<template>
    <div class='d-flex align-items-center justify-content-center h-100'>
      <div class="stap-inner">
        <h1><span class='stap-number'>9.</span> Controle</h1>
        <div class="form-topic">
          <h3>{{aanmelding.activiteit.titel}}lessen voor {{ aanmelding.client.firstname }}</h3>
          <div class="summary-card summary-big-card w-100">
            <div>
              <h6>Cursus</h6>
              <h4 class='h-themed'>{{aanmelding.cursus.titel}}</h4>
              <h6><strong>{{aanmelding.cursus.periode}}</strong> </h6>
              <p>{{aanmelding.cursus.omschrijving}}</p>
              <div v-if="aanmelding.voorkeur && aanmelding.voorkeur.length == 2 && aanmelding.voorkeur[0] && aanmelding.voorkeur[1]">
                <hr>
                <h6>Voorkeursmomenten</h6>
                <p id="voorkeursmoment" class="mb-1">
                  {{aanmelding.voorkeur[0].dag.dagDagdeel.dag.description}}{{aanmelding.voorkeur[0].dag.dagDagdeel.dagdeel.periode}}
                  en
                  {{aanmelding.voorkeur[1].dag.dagDagdeel.dag.description}}{{aanmelding.voorkeur[1].dag.dagDagdeel.dagdeel.periode}}
                </p>
              </div>
            </div>
          </div>
          <div class="summary-card summary-big-card w-100">
            <div class="row">
              <div class="col-12">
                <h6>Ervaring van {{ aanmelding.client.firstname }}</h6>
                <h4 class='mb-0' v-if="niveau">{{ niveau }}</h4>
              </div>
              <div v-if="!niveau">
                <div class="col-md-12">
                  <p>
                    {{ aanmelding.client.firstname }} heeft {{ aanmelding.ervaring.aantalWeken }} weken sneeuw-ervaring<span v-if="aanmelding.ervaring.laatsteJaar">
                     en {{ disciplineLast }} {{ aanmelding.ervaring.laatsteJaar }}
                    </span>.
                  </p>
                </div>
                <div class="row">
                <ul class="col-md-8 text-start">
                  <h6 class="d-block d-md-none">Technieken</h6>
                    <li
                        v-for="(item, index) in this.$store.state.options.technieken"
                        :key="index"
                        class="li-opt"
                        :class="aanmelding.ervaring.technieken && aanmelding.ervaring.technieken.includes(item.id) ? 'selected' : ''"
                    >
                      <span class='techniek-check check-true' v-if='aanmelding.ervaring.technieken && aanmelding.ervaring.technieken.includes(item.id) '>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      </span>
                      <span class='techniek-check check-false' v-else>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      </span>

                      {{item.titel}}
                    </li>
                </ul>

                <ul class="col-md-4 text-start">
                    <h6 class="d-block d-md-none">Pistes</h6>
                    <li
                        v-for="(item, index) in this.$store.state.options.pistes"
                        :key="index"
                        class="li-opt"
                        :class="aanmelding.ervaring.technieken && aanmelding.ervaring.pistes.includes(item.id) ? 'selected' : ''"
                    >
                      <span class='techniek-check check-true' v-if='aanmelding.ervaring.technieken && aanmelding.ervaring.pistes.includes(item.id) '>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      </span>
                      <span class='techniek-check check-false' v-else>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      </span>

                      {{item.titel}}
                    </li>
                </ul>
              </div>
              </div>
            </div>
          </div>
          <div class="summary-card summary-big-card col-12 d-block">
            <div class="row">
              <div class="col-md-6 mb-2 mb-md-0">
                <h6>Materiaal</h6>
                <h5>{{aanmelding.materiaal.titel}}</h5>
              </div>
              <div class="col-md-6">
                <h6>Skikaart</h6>
                <h5>{{aanmelding.skikaart.skikaart1.split(/[:()]+/)[0]}}</h5>
              </div>
            </div>
          </div>
          <div  v-if='aanmelding.opmerking !== ""' class="summary-card summary-big-card w-100">
            <div class="w-100">
              <h6>Opmerkingen</h6>
              <p class="mb-0">{{aanmelding.opmerking}}</p>
            </div>
          </div>
          <div class="summary-card summary-big-card w-100">
            <div class="row w-100">
                <div class="form-check">
                    <input id="controle-akkoord" type="checkbox" class="me-3" v-model="controleAkkoord">
                    <label for="controle-akkoord">Ik heb de aanmelding gecontroleerd.</label>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StapControle',
  mounted: function () {
    window.scrollTo(0, 0)
  },
  computed: {
    aanmelding () {
      return this.$store.state.aanmelding
    },
    niveau () {
      if (this.$store.state.aanmelding.ervaring.eerder === false) {
        return 'Beginner'
      } else if (this.$store.state.aanmelding.ervaring.afgelopenSeizoen === true) {
        return 'Bekend bij de skipiste'
      }
      return false
    },
    controleAkkoord: {
      get: function () {
        return this.$store.state.aanmelding.akkoord
      },
      set: function (value) {
        this.$store.dispatch('aanmelding/setAkkoord', value)
      }
    },
    disciplineLast () {
      switch (this.$store.state.aanmelding.activiteit.titel) {
        case 'Ski':
          return 'heeft voor het laatst geskied in'
        case 'Snowboard':
          return 'heeft voor het laatst gesnowboard in'
        default:
          return 'heeft voor het laatst les gehad in'
      }
    }
  }
}
</script>

<style scoped>
.stap-inner{
  margin: 0 -20px;
}
#opmerking {
  border: none;
  border-radius: 10px;
}

.summary-card.summary-big-card{
  padding: 24px;
  cursor: default;
}

#voorkeursmoment::first-letter{
  text-transform: capitalize;
}

.li-opt{
  font-size: 1rem;
}
.li-opt.selected{
  font-weight: 700;
}

.techniek-check svg{
  height: 14px;
}

.techniek-check.check-false svg{
  fill: rgba(0,0,0,.23)
}

label{
    font-size: 1.1rem;
    font-weight: 500;
}
.form-topic h5{
  font-size: 1.1rem;
}
.form-select, .form-control{
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    width: 200px;
}
</style>
